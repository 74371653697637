import logo from './images/logo.svg';
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './Navbar';
import FrontPage from './FrontPage';
import TextBoxOverlay from './TextBoxOverlay';

import Documentation from './Documentation';


function App() {
  return (
    <Router>
      <div className="App">
        <Navbar/>
        <Routes>
          <Route path="/" element={<FrontPage/>} />
          <Route path='/documentation' element={<Documentation/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
