import React from 'react';
import './FrontPage.css';
import nyseLogo from './images/NYSE.png'
import nasdaqLogo from './images/NASDAQ.png'
import shanghaiLogo from './images/SSE.png'
import londonLogo from './images/LSE.png'
import euronextLogo from './images/EURONEXT.png'
import torontoLogo from './images/TMX.png'
import placeholder from './images/Placeholder.png'
import TextBoxOverlay from './TextBoxOverlay'
import ProductInfo from './ProductInfo';
import Footer from './Footer'; // Adjust the path if needed
const FrontPage = () => {
  return (
    <div>
      <div className="front-page">
        {/* <img src={backdrop} alt="Backdrop" className="backdrop" /> */}
        <div className='backdrop'></div>
        <div className="content">
          <TextBoxOverlay>
            <div>
            <h1 className='align-left'>Your Gateway to Global Financial Data</h1>
            <h3 className='align-left'>DorsaFin Real-Time RESTful API gives you the tools to search for stock and option data like never before.</h3>
            <p  className='align-left'>Tap into real-time and historical data from major stock exchanges worldwide.</p>
            <p  className='align-left'> From equities to commodities, get the data you need at your fingertips.</p>
            {/* <img src={placeholder} className='align-right'></img> */}
            </div>
      
            <div className='align-left'>
            <input type="button" id="freeTrialBtn" value="Try for free" className='btn'/>          
            &nbsp; &nbsp;
            <input type='button' id='exploreBtn' value="Or explore Dorsa Financial »" className='btn-alt'></input>
            </div>
            <p color="#D5D0D7" class="css-bnr459-UppercaseOneLiner e6bq8xs3">Providing users with data from stock exchanges across the globe</p>
            <div>
              <img className='img-nyse' src={nyseLogo}></img>
              &nbsp;
              <img className='img-nyse' src={nasdaqLogo}></img>
              &nbsp;
              <img className='img-nyse' src={shanghaiLogo}></img>
              &nbsp;
              <img className='img-nyse' src={londonLogo}></img>
              &nbsp;
              <img className='img-nyse' src={euronextLogo}></img>
              &nbsp;
              <img className='img-nyse' src={torontoLogo}></img>
            </div>
          </TextBoxOverlay>
    
        </div>      
      </div>
      <ProductInfo/>
      <Footer/>
    </div>

  );
};

export default FrontPage;
