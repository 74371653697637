// Footer.js
import React from "react";
import './Footer.css';
import logo from './images/logo.png'
function Footer() {
    return (
      // <div className="app-footer">
       <footer className="app-footer">
         {/* Footer content goes here */}
         <img src={logo} className='logo-image' />
         <p>&copy; 2023 Dorsa Financial. All rights reserved.</p>
      </footer>
      //</div>

    );
  }
  
  export default Footer;