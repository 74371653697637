import React from 'react';
import './Navbar.css';
import logo from './images/logo.png'
import { Link } from 'react-router-dom';
function Navbar() {
  return (
    <nav className="navbar">
      <div className="logo">
        <Link  to="/" className="logo-link">
      <img src={logo} className='logo-image' /> 
      </Link >
      </div>
      <ul className="menu">
        <li><Link  to="/">Home</Link ></li>
        <li><Link  to="/documentation">Documentation</Link ></li>
        <li><Link  to="/pricing">Pricing</Link ></li>
        <li><Link  to="/contact">Contact</Link ></li>
      </ul>
    </nav>
  );
}

export default Navbar;
