import React from "react";

function Documentation() {
    return (
        <div className="documentation-container">
      
        {/* Title Section */}
        <h1 className="doc-title">API Documentation</h1>
        
        {/* Introduction Section */}
        <section className="doc-section">
          <h2>Introduction</h2>
          <p>Welcome to our API documentation. Here you'll find comprehensive guides and documentation to help you start working with our API as quickly as possible.</p>
        </section>
        
        {/* Code Snippet Section */}
        <section className="doc-section">
          <h2>Getting Started</h2>
          <p>To get started, make a POST request to the following endpoint:</p>
          <pre className="code-snippet">
            <code>
              {`fetch('https://api.example.com/data', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ key: 'value' })
                });`}
            </code>
          </pre>
        </section>
        
        {/* Additional Sections */}
        <section className="doc-section">
          <h2>Authentication</h2>
          <p>Details about API authentication...</p>
        </section>
        
        {/* ... add more sections as needed ... */}
  
        {/* Footer */}
        <footer>
          <p>For further assistance, please contact our support team.</p>
        </footer>
        
      </div>
    );
}

export default Documentation;