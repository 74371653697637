import React from 'react';
import './ProductInfo.css';
import graph1 from './images/better_graph_resized.png'
import city from './images/cityscape_resized.png'
const ProductInfo = () => {
    return (
        <div>
            <div className="gradient-img">           
            </div>
            <div className='product-info'>
                <div className='product-header'>
                    <h1 className="product-heading">One purpose. One Vision.</h1>
                    <hr className='product-line'></hr>
                </div>

                <div className="product-info-flex">
                    <div  className="heading-1">
                        <h1 className="text-stocks-top">Stock Query</h1>
                        <img className='stock-image' src={graph1}></img>
                        <h2 className="text-stocks">The Future of Stock Analysis</h2>
                        <p>Dive deep into the world of stocks with our cutting-edge API, designed to empower your financial decisions. With access to over 750 unique data items, you're equipped with the most comprehensive stock data at your fingertips.</p>
                        <h2 className="text-stocks">SQL-Like Queries, Simplified</h2>
                        <p>Send us your queries in an intuitive, SQL-inspired format, and let our advanced parsing engine do the heavy lifting. No more complex setups or steep learning curves.</p>
                        <h2 className="text-stocks">Instant, Accurate Results</h2>
                    </div>
                    <div  className="heading-2">
                        <h1 className="text-stocks-top">Options</h1>
                        <img className='stock-image'src={city}></img>
                        <h2 className="text-stocks">Fundamental Data</h2>
                        <p>Irure cillum sit do officia aliqua anim aute laborum id laborum aliquip irure veniam aliquip. Deserunt ea consequat esse pariatur. Qui in commodo labore in laboris dolor. Consequat culpa eiusmod cupidatat exercitation esse laborum excepteur. Ea sint anim duis ea dolore amet deserunt.</p>
                    </div>

                    <div  className="heading-3">
                        <h1 className="text-stocks-top">Options</h1>
                        <img className='stock-image'src={city}></img>
                        <h2 className="text-stocks">Fundamental Data</h2>
                        <p>Irure cillum sit do officia aliqua anim aute laborum id laborum aliquip irure veniam aliquip. Deserunt ea consequat esse pariatur. Qui in commodo labore in laboris dolor. Consequat culpa eiusmod cupidatat exercitation esse laborum excepteur. Ea sint anim duis ea dolore amet deserunt.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductInfo;