// TextBoxOverlay.js
import React from 'react';
import './TextBoxOverlay.css';

function TextBoxOverlay({ children }) {
  return (
    <div className="text-box-overlay">
      {children}
    </div>
  );
}

export default TextBoxOverlay;
